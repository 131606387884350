import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Happy Halloween!`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`There will be no 5:30 class today at Louisville East so you can go
trick or treating! There will be a 4:30 & 5:30 class at The Ville today
but we will close directly after class so no open gym from 6:30-8.`}{`*`}</strong></p>
    <p>{`10:00 EMOM of:`}</p>
    <p>{`1-Power Snatch`}</p>
    <p>{`1-Hang Squat Snatch`}</p>
    <p>{`1-Overhead Squat`}</p>
    <p>{`(using 80% 1RM of weakest movement)`}</p>
    <p>{`then, at minute 12:00`}</p>
    <p>{`3 Rounds for time of`}</p>
    <p>{`30-OH Walking Lunges (45/25)`}</p>
    <p>{`20-Power Snatches (75/55)`}</p>
    <p>{`10-HSPU’s`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Open gym at Louisville East today from 11:30-12:30 & 2:30-4:00.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`True Grit 4 is filled with teams ready to rumble for 1st place this
Saturday, November 5th! Come out and watch the action at The Ville
starting at 9:00am. `}<a parentName="em" {...{
            "href": "https://truegritcomp.wordpress.com"
          }}>{`https://truegritcomp.wordpress.com`}</a></em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There will be no 5:30pm class or open gym after 5:30 this Friday at
The Ville so that we can set up for True Grit. If anyone is available to
help set up please show up at 5:30.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We are collecting blankets, scarves and gloves at Louisville East
and The Ville for the homeless this winter. If you’d like to donate
please do so by November 12th. There are bins at each location for your
donations. Thanks!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts Sunday, November 6th. For more
info contact Eric at fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      